var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.malli.registry.js");
'use strict';var Wga,Xga,Yga,Zga,$ga,aha,bha,LF,cha,dha,eha,fha,gha,hha,iha,jha,NF,kha;$CLJS.HF=new $CLJS.M(null,"supported-field","supported-field",-2061545519);Wga=new $CLJS.M("mbql.clause","distinct","mbql.clause/distinct",843025748);$CLJS.IF=new $CLJS.M("metabase.lib.schema.aggregation","aggregations","metabase.lib.schema.aggregation/aggregations",-203649172);Xga=new $CLJS.M("mbql.clause","count-where","mbql.clause/count-where",2010666417);
Yga=new $CLJS.M("mbql.clause","sum-where","mbql.clause/sum-where",-1599647617);$CLJS.JF=new $CLJS.M(null,"requires-column?","requires-column?",1623131448);Zga=new $CLJS.M("metabase.lib.schema.aggregation","operator","metabase.lib.schema.aggregation/operator",2070038764);$CLJS.KF=new $CLJS.M(null,"display-info","display-info",-816930907);$ga=new $CLJS.M("mbql.clause","max","mbql.clause/max",1615385533);aha=new $CLJS.M("mbql.clause","cum-count","mbql.clause/cum-count",-1408720642);
bha=new $CLJS.M("mbql.clause","median","mbql.clause/median",-1026386338);LF=new $CLJS.M("metabase.lib.schema.aggregation","percentile.percentile","metabase.lib.schema.aggregation/percentile.percentile",-144637711);cha=new $CLJS.M("mbql.clause","min","mbql.clause/min",2074448531);dha=new $CLJS.M("mbql.clause","percentile","mbql.clause/percentile",2064253651);eha=new $CLJS.M("mbql.clause","stddev","mbql.clause/stddev",659381076);fha=new $CLJS.M("mbql.clause","var","mbql.clause/var",809434920);
gha=new $CLJS.M("mbql.clause","share","mbql.clause/share",-37921592);hha=new $CLJS.M("mbql.clause","count","mbql.clause/count",-454225910);iha=new $CLJS.M("mbql.clause","cum-sum","mbql.clause/cum-sum",468410018);$CLJS.MF=new $CLJS.M(null,"driver-feature","driver-feature",1905324248);jha=new $CLJS.M("mbql.clause","avg","mbql.clause/avg",1826863173);NF=new $CLJS.M("metabase.lib.schema.aggregation","aggregation","metabase.lib.schema.aggregation/aggregation",-736862750);
$CLJS.OF=new $CLJS.M("operator","aggregation","operator/aggregation",-1961510924);kha=new $CLJS.M("mbql.clause","sum","mbql.clause/sum",1691012107);$CLJS.wE($CLJS.Rw,$CLJS.H([$CLJS.Zr,$CLJS.cj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qA,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Oq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.VD],null)],null)],null)],null)]));$CLJS.wE($CLJS.iF,$CLJS.H([$CLJS.Zr,$CLJS.cj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qA,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Oq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.VD],null)],null)],null)],null)]));
$CLJS.uE($CLJS.bF,$CLJS.H([$CLJS.Zr,$CLJS.LC,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fE],null)],null)]));$CLJS.uE($CLJS.ME,$CLJS.H([$CLJS.Zr,$CLJS.cj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.VD],null)],null)]));$CLJS.uE($CLJS.qF,$CLJS.H([$CLJS.Zr,$CLJS.cj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UD],null)],null)]));
$CLJS.uE($CLJS.Gj,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aE],null)],null)]));$CLJS.nE($CLJS.Gj,$CLJS.$D);$CLJS.uE($CLJS.uF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fE],null)],null)]));$CLJS.nE($CLJS.uF,$CLJS.$D);$CLJS.uE($CLJS.ui,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aE],null)],null)]));$CLJS.nE($CLJS.ui,$CLJS.$D);
$CLJS.Y(LF,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kr,new $CLJS.h(null,1,[$CLJS.ts,"valid percentile"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ci,new $CLJS.h(null,1,[$CLJS.ts,"percentile must be between zero and one"],null),function(a){return 0<=a&&1>=a}],null)],null));$CLJS.uE($CLJS.TE,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LF],null)]));$CLJS.nE($CLJS.TE,$CLJS.$D);
$CLJS.uE($CLJS.oF,$CLJS.H([$CLJS.Zr,$CLJS.LC,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UD],null)],null)]));$CLJS.uE($CLJS.vF,$CLJS.H([$CLJS.Zr,$CLJS.LC,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fE],null)],null)]));$CLJS.uE($CLJS.tF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fE],null)],null)]));
$CLJS.uE($CLJS.CF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fE],null)],null)]));$CLJS.nE($CLJS.tF,$CLJS.$D);$CLJS.nE($CLJS.CF,$CLJS.$D);$CLJS.uE($CLJS.AF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UD],null)],null)]));$CLJS.nE($CLJS.AF,$CLJS.$D);
$CLJS.uE($CLJS.NE,$CLJS.H([$CLJS.Zr,$CLJS.LC,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fE],null)],null)]));$CLJS.Y(NF,new $CLJS.P(null,17,5,$CLJS.Q,[$CLJS.fr,jha,hha,aha,Xga,Wga,$ga,bha,cha,dha,gha,eha,kha,iha,Yga,fha,$CLJS.dl],null));$CLJS.Y($CLJS.IF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fj,new $CLJS.h(null,1,[$CLJS.ui,1],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NF],null)],null));
$CLJS.PF=new $CLJS.P(null,10,5,$CLJS.Q,[new $CLJS.h(null,4,[$CLJS.aF,$CLJS.Rw,$CLJS.JF,!1,$CLJS.MF,$CLJS.BE,$CLJS.KF,function(){return new $CLJS.h(null,3,[$CLJS.jD,$CLJS.GD("Count of rows"),$CLJS.SE,$CLJS.GD("Count"),$CLJS.Hs,$CLJS.GD("Total number of rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.aF,$CLJS.tF,$CLJS.HF,$CLJS.Dj,$CLJS.JF,!0,$CLJS.MF,$CLJS.BE,$CLJS.KF,function(){return new $CLJS.h(null,3,[$CLJS.jD,$CLJS.GD("Sum of ..."),$CLJS.SE,$CLJS.GD("Sum"),$CLJS.Hs,$CLJS.GD("Sum of all the values of a column.")],
null)}],null),new $CLJS.h(null,5,[$CLJS.aF,$CLJS.bF,$CLJS.HF,$CLJS.Dj,$CLJS.JF,!0,$CLJS.MF,$CLJS.BE,$CLJS.KF,function(){return new $CLJS.h(null,3,[$CLJS.jD,$CLJS.GD("Average of ..."),$CLJS.SE,$CLJS.GD("Average"),$CLJS.Hs,$CLJS.GD("Average of all the values of a column")],null)}],null),new $CLJS.h(null,5,[$CLJS.aF,$CLJS.uF,$CLJS.HF,$CLJS.Dj,$CLJS.JF,!0,$CLJS.MF,$CLJS.dF,$CLJS.KF,function(){return new $CLJS.h(null,3,[$CLJS.jD,$CLJS.GD("Median of ..."),$CLJS.SE,$CLJS.GD("Median"),$CLJS.Hs,$CLJS.GD("Median of all the values of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.aF,$CLJS.ME,$CLJS.HF,$CLJS.ur,$CLJS.JF,!0,$CLJS.MF,$CLJS.BE,$CLJS.KF,function(){return new $CLJS.h(null,3,[$CLJS.jD,$CLJS.GD("Number of distinct values of ..."),$CLJS.SE,$CLJS.GD("Distinct values"),$CLJS.Hs,$CLJS.GD("Number of unique values of a column among all the rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.aF,$CLJS.CF,$CLJS.HF,$CLJS.Dj,$CLJS.JF,!0,$CLJS.MF,$CLJS.BE,$CLJS.KF,function(){return new $CLJS.h(null,3,[$CLJS.jD,$CLJS.GD("Cumulative sum of ..."),
$CLJS.SE,$CLJS.GD("Sum"),$CLJS.Hs,$CLJS.GD("Additive sum of all the values of a column.\ne.x. total revenue over time.")],null)}],null),new $CLJS.h(null,4,[$CLJS.aF,$CLJS.iF,$CLJS.JF,!1,$CLJS.MF,$CLJS.BE,$CLJS.KF,function(){return new $CLJS.h(null,3,[$CLJS.jD,$CLJS.GD("Cumulative count of rows"),$CLJS.SE,$CLJS.GD("Count"),$CLJS.Hs,$CLJS.GD("Additive count of the number of rows.\ne.x. total number of sales over time.")],null)}],null),new $CLJS.h(null,5,[$CLJS.aF,$CLJS.vF,$CLJS.HF,$CLJS.Dj,$CLJS.JF,
!0,$CLJS.MF,$CLJS.rF,$CLJS.KF,function(){return new $CLJS.h(null,3,[$CLJS.jD,$CLJS.GD("Standard deviation of ..."),$CLJS.SE,$CLJS.GD("SD"),$CLJS.Hs,$CLJS.GD("Number which expresses how much the values of a column vary among all rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.aF,$CLJS.ui,$CLJS.HF,$CLJS.Ki,$CLJS.JF,!0,$CLJS.MF,$CLJS.BE,$CLJS.KF,function(){return new $CLJS.h(null,3,[$CLJS.jD,$CLJS.GD("Minimum of ..."),$CLJS.SE,$CLJS.GD("Min"),$CLJS.Hs,$CLJS.GD("Minimum value of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.aF,$CLJS.Gj,$CLJS.HF,$CLJS.Ki,$CLJS.JF,!0,$CLJS.MF,$CLJS.BE,$CLJS.KF,function(){return new $CLJS.h(null,3,[$CLJS.jD,$CLJS.GD("Maximum of ..."),$CLJS.SE,$CLJS.GD("Max"),$CLJS.Hs,$CLJS.GD("Maximum value of a column")],null)}],null)],null);
$CLJS.Y(Zga,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kq,$CLJS.OF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aF,$CLJS.Wf.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.cr],null),$CLJS.cf.h($CLJS.aF),$CLJS.PF)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HF,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,$CLJS.Ji],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.JF,$CLJS.tr],null),new $CLJS.P(null,2,5,
$CLJS.Q,[$CLJS.MF,$CLJS.Ji],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.KF,$CLJS.id],null)],null));