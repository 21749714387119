var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./malli.core.js");require("./metabase.lib.common.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.types.js");require("./metabase.util.malli.js");
'use strict';var B0,wpa,C0,D0;$CLJS.z0=function(a,b){return $CLJS.cc($CLJS.cb(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.bc(c,d):c},$CLJS.ac($CLJS.xf),b))};B0=function(a){return $CLJS.n(A0)?["(",$CLJS.p.h(a),")"].join(""):a};wpa=function(a,b){var c=$CLJS.nk.v(a,$CLJS.$O,$CLJS.QC($CLJS.Yd,$CLJS.xf),b);return $CLJS.ud($CLJS.PD.h(a))?$CLJS.nk.v(c,$CLJS.PD,$CLJS.Yd,$CLJS.pV(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qA,$CLJS.N,$CLJS.yV(b)],null))):c};
C0=function(a,b,c){var d=$CLJS.yV(c);return $CLJS.R.l($CLJS.k_.j(a,b,c),$CLJS.LJ,$CLJS.FY,$CLJS.H([$CLJS.U,d,$CLJS.jD,d]))};D0=new $CLJS.M("metabase.lib.expression","infix-operator","metabase.lib.expression/infix-operator",246733613);$CLJS.E0=new $CLJS.M("lib","source-uuid","lib/source-uuid",1828897581);var F0=function(){function a(d,e,f){var k=$CLJS.FV(d,e);k=$CLJS.qG($CLJS.kk.g($CLJS.Tg([f]),$CLJS.yV),$CLJS.$O.h(k));if($CLJS.n(k))return k;throw $CLJS.Uh($CLJS.eD("No expression named {0}",$CLJS.H([$CLJS.xh.l($CLJS.H([f]))])),new $CLJS.h(null,3,[$CLJS.$Q,f,$CLJS.DE,d,$CLJS.FK,e],null));}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);
};c.g=b;c.j=a;return c}();$CLJS.S_.m(null,$CLJS.qA,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);c=F0.j(a,b,c);return $CLJS.T_.j(a,b,c)});$CLJS.U_.m(null,$CLJS.qA,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.I(c,2,null);return new $CLJS.h(null,7,[$CLJS.Vs,$CLJS.JJ,$CLJS.E0,$CLJS.vD.h(d),$CLJS.U,e,$CLJS.nD,e,$CLJS.jD,$CLJS.m_.j(a,b,c),$CLJS.tA,$CLJS.T_.j(a,b,c),$CLJS.LJ,$CLJS.FY],null)});$CLJS.N_.m(null,$CLJS.lA,function(a,b,c){return $CLJS.p.h(c)});
$CLJS.N_.m(null,$CLJS.jA,function(a,b,c){return $CLJS.p.h(c)});$CLJS.N_.m(null,$CLJS.kA,function(a,b,c){return['"',$CLJS.p.h(c),'"'].join("")});$CLJS.N_.m(null,$CLJS.zA,function(a,b,c){return $CLJS.p.h(c)});$CLJS.N_.m(null,$CLJS.qA,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);return $CLJS.I(c,2,null)});$CLJS.O_.m(null,$CLJS.qA,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);return $CLJS.I(c,2,null)});
for(var A0=!1,xpa=new $CLJS.h(null,4,[$CLJS.Mq,"+",$CLJS.Zr,"-",$CLJS.Nq,"×",$CLJS.BF,"÷"],null),G0=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Mq,$CLJS.Zr,$CLJS.BF,$CLJS.Nq],null)),H0=null,I0=0,J0=0;;)if(J0<I0){var ypa=H0.X(null,J0);$CLJS.nE(ypa,D0);J0+=1}else{var K0=$CLJS.y(G0);if(K0){var L0=K0;if($CLJS.vd(L0)){var M0=$CLJS.ic(L0),zpa=$CLJS.jc(L0),Apa=M0,Bpa=$CLJS.D(M0);G0=zpa;H0=Apa;I0=Bpa}else{var Cpa=$CLJS.z(L0);$CLJS.nE(Cpa,D0);G0=$CLJS.B(L0);H0=null;I0=0}J0=0}else break}
$CLJS.N_.m(null,D0,function(a,b,c){var d=$CLJS.y(c);c=$CLJS.z(d);d=$CLJS.B(d);$CLJS.z(d);d=$CLJS.B(d);c=$CLJS.J.g(xpa,c);a:{var e=A0;A0=!0;try{var f=$CLJS.hs([" ",$CLJS.Xg(c)," "].join(""),$CLJS.cf.g($CLJS.Xs($CLJS.m_,a,b),d));break a}finally{A0=e}f=void 0}return B0(f)});$CLJS.O_.m(null,D0,function(){return"expression"});
$CLJS.S_.m(null,$CLJS.xG,function(a,b,c){c=$CLJS.y(c);$CLJS.z(c);c=$CLJS.B(c);$CLJS.z(c);var d=$CLJS.B(c);return $CLJS.Md($CLJS.pE,function(){return function k(f){return new $CLJS.ie(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.vd(l)){var m=$CLJS.ic(l),t=$CLJS.D(m),u=$CLJS.le(t);a:for(var v=0;;)if(v<t){var x=$CLJS.hd(m,v);x=$CLJS.T_.j(a,b,x);u.add(x);v+=1}else{m=!0;break a}return m?$CLJS.oe($CLJS.qe(u),k($CLJS.jc(l))):$CLJS.oe($CLJS.qe(u),null)}u=$CLJS.z(l);return $CLJS.ae($CLJS.T_.j(a,
b,u),k($CLJS.Hc(l)))}return null}},null,null)}(d)}())});$CLJS.N_.m(null,$CLJS.bB,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null),f=$CLJS.I(c,3,null);c=$CLJS.I(c,4,null);a=$CLJS.p.h($CLJS.m_.v(a,b,e,d));b=$CLJS.p;d=b.h;c=$CLJS.t0.g(f,c).toLowerCase();0<f?f=$CLJS.ha.j?$CLJS.ha.j("+ %d %s",f,c):$CLJS.ha.call(null,"+ %d %s",f,c):(f=$CLJS.yz(f),f=$CLJS.ha.j?$CLJS.ha.j("- %d %s",f,c):$CLJS.ha.call(null,"- %d %s",f,c));f=B0(f);return[a," ",d.call(b,f)].join("")});
$CLJS.O_.m(null,$CLJS.bB,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null),e=$CLJS.I(c,3,null);c=$CLJS.I(c,4,null);a=$CLJS.p.h($CLJS.P_.j(a,b,d));b=$CLJS.p;d=b.h;c=$CLJS.t0.g(e,c).toLowerCase();0<e?e=$CLJS.ha.j?$CLJS.ha.j("plus_%s_%s",e,c):$CLJS.ha.call(null,"plus_%s_%s",e,c):(e=$CLJS.yz(e),e=$CLJS.ha.j?$CLJS.ha.j("minus_%d_%s",e,c):$CLJS.ha.call(null,"minus_%d_%s",e,c));return[a,"_",d.call(b,e)].join("")});
$CLJS.N_.m(null,$CLJS.kF,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.m_.v(a,b,e,d)});$CLJS.O_.m(null,$CLJS.kF,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.P_.j(a,b,d)});
$CLJS.Dpa=function(){function a(d,e,f,k){return $CLJS.QV.l(d,$CLJS.n(e)?e:-1,wpa,$CLJS.H([$CLJS.zV($CLJS.rV.h(k),f)]))}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();
$CLJS.Epa=function(){function a(d,e){var f=$CLJS.Be($CLJS.$O.h($CLJS.FV(d,e)));return null==f?null:$CLJS.mk.g($CLJS.Xs(C0,d,e),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.N0=function(){function a(d,e){return $CLJS.Be($CLJS.$O.h($CLJS.FV(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();$CLJS.oV.m(null,$CLJS.qA,function(a){return a});
$CLJS.Fpa=function(){function a(d,e,f){var k=$CLJS.Wf.g($CLJS.N,$CLJS.El(function(m,t){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yV(t),m],null)},$CLJS.N0.g(d,e))),l=$CLJS.FV(d,e);d=$CLJS.Z_.j(d,e,l);return $CLJS.Be($CLJS.z0(function(m){return $CLJS.Ra(f)||$CLJS.jk.g($CLJS.LJ.h(m),$CLJS.FY)||$CLJS.J.g(k,$CLJS.U.h(m))<f},d))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+
arguments.length);};c.g=b;c.j=a;return c}();$CLJS.Gpa=function(){function a(d,e,f){return $CLJS.qV(C0(d,e,F0.j(d,e,f)))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.O0=$CLJS.kE.h($CLJS.VD);